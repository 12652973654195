	/*
  	Flaticon icon font: Flaticon
  	Creation date: 08/09/2017 09:55
  	*/

@font-face {
  font-family: "Flaticon";
  src: url("./Flaticon.eot");
  src: url("./Flaticon.eot?#iefix") format("embedded-opentype"),
       url("./Flaticon.woff") format("woff"),
       url("./Flaticon.ttf") format("truetype"),
       url("./Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("./Flaticon.svg#Flaticon") format("svg");
  }
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
  font-family: Flaticon;
        font-size: 20px;
font-style: normal;
margin-left: 20px;
}

.flaticon-contact:before { content: "\f100"; }
.flaticon-education:before { content: "\f101"; }
.flaticon-newspaper-report:before { content: "\f102"; }
.flaticon-management-notes:before { content: "\f103"; }
.flaticon-magistrate-with-hammer:before { content: "\f104"; }
.flaticon-book:before { content: "\f105"; }
.flaticon-about:before { content: "\f106"; }
.flaticon-sheet:before { content: "\f107"; }
.flaticon-tool:before { content: "\f108"; }
.flaticon-arrows:before { content: "\f109"; }
.flaticon-videocast:before { content: "\f10a"; }
.flaticon-directions:before { content: "\f10b"; }
.flaticon-docs:before { content: "\f10c"; }
.flaticon-patient-panel:before { content: "\f10d"; }
.flaticon-science:before { content: "\f10e"; }
.flaticon-archive:before { content: "\f10f"; }
.flaticon-calendar:before { content: "\f110"; }
.flaticon-patient-communication:before { content: "\f111"; }
.flaticon-news:before { content: "\f112"; }
.flaticon-medical:before { content: "\f113"; }
.flaticon-people:before { content: "\f114"; }
.flaticon-technology:before { content: "\f115"; }
.flaticon-social:before { content: "\f116"; }
.flaticon-registration:before {content: "\f1023";}